'use strict';
angular.module('kerp-forms.forms').factory(
  'COV_FCA_SCHEMA',
  [ 'fieldDefinitionService', 'dateUtils', 'htmlService', '$window',
    function (fieldDefinitionService, dateUtils, htmlService, $window) {

      var constants = $window.kerpCfa.coventry.constants;

      var attendanceAllowances = constants.attendanceAllowance;
      var mobilityAllowances = constants.mobilityAllowance;
      var dlaCareAllowances = constants.dlaCare;
      var pipDailyAllowances = constants.pipDailyLiving;

      function toTitleMapEntry(level, amount) {
        return {value: level, name: amount.toFixed(2)};
      }

      function getBreakdown() {
        return {
          type: 'object',
          title: 'Breakdown',
          properties: {
            grossIncome: fieldDefinitionService.string.breakdownField({
              title: 'Gross Income',
              description: 'This is the weekly total of all the income you currently receive.'
            }),
            capitalTariff: fieldDefinitionService.string.breakdownField({
              title: 'Capital Tariff Income',
              description: "Capital over £14,250 is treated as providing an income of £1 per week for each additional £250 (or part thereof) above the threshold. This is known as 'Capital tariff income'. For example: Capital of £14,500 gives a weekly capital tariff income of £1, whereas capital of £14,500.01 gives a weekly capital tariff income of £2."
            }),
            disregardsTotal: fieldDefinitionService.string.breakdownField({
              title: 'Income Disregards Total',
              description: "Some proportion of the money or benefits you receive may be disregarded. For example: any wages. The amount shown above is the amount that the council disregards from your 'Gross Income'."
            }),
            expensesTotal: fieldDefinitionService.string.breakdownField({
              title: 'Expenses Total',
              description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/COV_FCA/templates/expensesTotalDescription.html')
            }),
            dreTotal: fieldDefinitionService.string.breakdownField({
              title: 'Disability Related Expenses Total',
              description: "The council can make allowances if you are assessed as incurring 'above average costs' due to your age, disability or ill health."
            }),
            livingAllowanceTotal: fieldDefinitionService.string.breakdownField({
              title: 'Applicant Allowances Total',
              description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/COV_FCA/templates/totalAllowancesDescription.html')
            })
          },
          required: [
            'grossIncome',
            'capitalTariff',
            'expensesTotal',
            'disregardsTotal',
            'livingAllowanceTotal',
            'dreTotal'
          ]
        };
      }

      function agentDetails(options) {
        return angular.extend({
          type: 'object',
          properties: {
            title: {
              title: 'Title',
              type: 'string'
            },
            name: fieldDefinitionService.default('First name'),
            surname: fieldDefinitionService.default('Last name'),

            addressLine1: fieldDefinitionService.address.requiredLine({title: 'Address line 1'}, true),
            addressLine2: fieldDefinitionService.address.requiredLine({title: 'Address line 2'}, true),
            addressLine3: fieldDefinitionService.address.optionalLine({title: 'Address line 3'}, true),
            postcode: {
              title: 'Postcode',
              type: 'string'
            },

            phoneNumber: fieldDefinitionService.phone.fullNumber({
              title: 'Telephone number'
            }),
            email: fieldDefinitionService.string.email({
              title: 'Email address'
            }),
            relationship: {
              title: 'Relationship to client',
              type: 'string'
            }
          },
          required: ['name', 'surname', 'addressLine1', 'addressLine2', 'postcode', 'relationship']
        }, options);
      }

      var addressField = fieldDefinitionService.address;

      function capital() {
        return {
          type: 'object',
          title: 'Capital',
          properties: {
            hasSavingsInvestments: fieldDefinitionService.boolean.yesNoSchema(),
            savingsInvestments: {
              type: 'array',
              minItems: 1,
              items: {
                type: "object",
                properties: {
                  name: {
                    title: 'Name of Bank/BS Shares/Bonds',
                    type: 'string',
                    'x-schema-form': {
                      feedback: false
                    }
                  },
                  accountNo: {
                    title: 'Last 4 Digits of Account No.',
                    type: 'string',
                    pattern: '^[0-9]{4}$',
                    validationMessage: 'Enter exactly four digits',
                    'x-schema-form': {
                      feedback: false
                    }
                  },
                  accountHolder: {
                    title: 'Account holder name(s)',
                    type: 'string',
                    'x-schema-form': {
                      feedback: false
                    }
                  },
                  amount: fieldDefinitionService.number.currencyField(
                    {title: 'Amount'},
                    {feedback: false}
                  )
                },
                required: ["name", "accountNo", "accountHolder", "amount"]
              }
            },

            hasShareholdings: fieldDefinitionService.boolean.yesNoSchema(),
            shareholdings: {
              type: 'array',
              minItems: 1,
              items: {
                type: "object",
                properties: {
                  companyName: {
                    title: 'Name of company',
                    type: 'string',
                    'x-schema-form': {
                      feedback: false
                    }
                  },
                  shareCount: {
                    title: 'Number of shares held',
                    type: 'integer',
                    'x-schema-form': {
                      feedback: false
                    }
                  },
                  totalValue: fieldDefinitionService.number.currencyField(
                    {title: 'Total value of shares'},
                    {feedback: false}
                  )
                },
                required: ["companyName", "shareCount", "totalValue"]
              }
            }

          },
          required: ['hasSavingsInvestments', 'hasShareholdings']
        };
      }

      function frequency(options) {
        return angular.extend({
          type: 'string',
          title: 'Frequency',
          "enum": ["Weekly", "Fortnightly", "4 Weekly", "Monthly", "Annually"],

          // this doesn't work for us, but it does if you try it here on the ASF example page
          // http://schemaform.io/examples/bootstrap-example.html
          "default": "Weekly"
        }, options);
      }

      function incomeProperties(isApplicant) {
        var income = {
          type: 'object',
          title: 'Income',
          properties: {
            hasUniversalCredit: fieldDefinitionService.boolean.yesNoSchema(),
            universalCreditBreakdown: {
              type: 'object',
              properties: {
                totalUCReceived: fieldDefinitionService.number.currencyField({
                  title: 'Total Universal Credit Received',
                  description: 'Universal Credit (UC) is made up of several elements. The elements you receive are listed on your UC award. In order to work out the amount of UC to use in your assessment, you need to enter all the elements of UC you receive and all the amounts deducted from your UC payment, as listed on your award letter.'
                }),
              },

              required: [
                'totalUCReceived'
              ]
            },


            hasWage: fieldDefinitionService.boolean.yesNoSchema(),

            wages: {
              type: 'array',
              minItems: 1,
              items: {
                type: 'object',
                properties: {
                  from: {
                    title: 'Employer',
                    type: 'string'
                  },
                  ref: {
                    title: 'Payroll Ref. No.',
                    type: 'string'
                  },
                  amount: fieldDefinitionService.number.currencyField(
                    {title: 'Amount (net)'},
                    {description: 'Enter the amount received after deductions and taxes have been taken off.'}
                  ),
                  amountPeriod: frequency()
                },
                required: ["from", "amount", "amountPeriod"]
              }
            },

            hasPrivatePension: fieldDefinitionService.boolean.yesNoSchema({
              description: 'Private pensions are a type of pension that you can set up to help you save money for retirement. This is separate from your workplace. A works pension is a way of saving for your retirement through contributions deducted direct from your wages. An annuity is a type of retirement income that you buy with some or all of your pension pot.'
            }),

            privatePensions: {
              type: 'array',
              minItems: 1,
              items: {
                type: "object",
                properties: {
                  from: {
                    title: 'Company',
                    type: 'string'
                  },
                  ref: {
                    title: 'Ref. No.',
                    type: 'string'
                  },
                  amount: fieldDefinitionService.number.currencyField(
                    {title: 'Amount (net)'},
                    {description: 'Enter the amount received after deductions and taxes have been taken off.'}
                  ),
                  amountPeriod: frequency()
                },
                required: ["from", "amount", "amountPeriod"]
              }
            },

            childTaxCredit: fieldDefinitionService.number.currencyField({
              title: 'Child Tax Credit',
              description: 'Child Tax Credit is a payment to help people with the costs of bringing up a child. Additional information is available on <a href="https://www.gov.uk/child-tax-credit">the government website</a>.'
            }),
            childTaxCreditPeriod: frequency(),

            workingTaxCredit: fieldDefinitionService.number.currencyField({
              title: 'Working Tax Credit',
              description: 'Working Tax Credit is a payment if you are on low income and work at least 16 hours a week. Additional information is available on <a href="https://www.gov.uk/working-tax-credit">the government website</a>.'
            }),
            workingTaxCreditPeriod: frequency(),

            incomeSupportPayment: fieldDefinitionService.number.currencyField({
              title: 'Income Support'
            }),
            incomeSupportPaymentPeriod: frequency(),

            employmentSupport: fieldDefinitionService.number.currencyField({
              title: 'Employment Support Allowance',
              description: 'Employment and Support Allowance is a payment if you have a disability or health condition that affects how much you can work. Additional information is available on <a href="https://www.gov.uk/employment-support-allowance">the government website</a>.'
            }),
            employmentSupportPeriod: frequency(),

            hasESAEnhancedDisabilityPremium: {
              type: 'string',
              'x-schema-form': {
                type: "radios",
                titleMap: [
                  {value: 'yes', name: "Yes"},
                  {value: 'no', name: "No"},
                  {value: 'unknown', name: "Don't know"}
                ]
              },
              title: 'Do you receive Enhanced Disability Premium as part of your Employment Support Allowance?',
            },

            severeDisablementAllowance: fieldDefinitionService.number.currencyField({
              title: 'Severe Disablement Allowance'
            }),
            severeDisablementAllowancePeriod: frequency(),

            jobSeekersAllowance: fieldDefinitionService.number.currencyField({
              title: 'Jobseeker’s Allowance',
              description: 'Jobseeker’s Allowance is a payment to help you when you look for work. Additional information is available on <a href="https://www.gov.uk/jobseekers-allowance">the government website</a>.'
            }),
            jobSeekersAllowancePeriod: frequency(),

            industrialInjuriesBenefit: fieldDefinitionService.number.currencyField({
              title: 'Industrial Injuries Benefit',
              description: 'Industrial Injuries Benefit is a payment if you became ill or are disabled because of an accident or disease at work or on an approved training scheme or course. Additional information is available on <a href="https://www.gov.uk/industrial-injuries-disablement-benefit">the government website</a>.'
            }),
            industrialInjuriesBenefitPeriod: frequency(),

            warWidowsPension: fieldDefinitionService.number.currencyField({
              title: 'War Widow(er) Pension'
            }),
            warWidowsPensionPeriod: frequency(),

            maintenance: fieldDefinitionService.number.currencyField({
              title: 'Maintenance - Spouse/Partner'
            }),
            maintenancePeriod: frequency(),

            rentalIncome: fieldDefinitionService.number.currencyField({
              title: 'Income from Tenants and Boarders'
            }),
            rentalIncomePeriod: frequency(),

            retirementPension: fieldDefinitionService.number.currencyField({
              title: 'State Retirement Pension',
              description: 'You may receive a basic State Pension if you’re: <ul><li> a man born before 6 April 1951</li><li> a woman born before 6 April 1953</li></ul> Additional information is available on <a href="https://www.gov.uk/state-pension">the government website</a>.'
            }),
            retirementPensionPeriod: frequency(),

            attendanceAllowance: fieldDefinitionService.number.mappedCurrencyField(
              {
                title: 'Weekly Attendance Allowance',
                description: 'Attendance Allowance is money for people who have reached <a href="https://www.gov.uk/state-pension-age">State Pension age</a> and have care needs because of physical or mental disability. Additional information is available on <a href="https://www.gov.uk/attendance-allowance">the government website</a>.',
                'enum': [
                  'high',
                  'low',
                  'none'
                ]
              }, {
                type: 'select',
                titleMap: [
                  toTitleMapEntry('high', attendanceAllowances.high.amount),
                  toTitleMapEntry('low', attendanceAllowances.low.amount),
                  {value: 'none', name: '0'}
                ]
              }
            ),

            dlaCare: fieldDefinitionService.number.mappedCurrencyField({
              title: 'Weekly Disability Living Allowance (DLA) Care',
              description: 'Disability Living Allowance is a payment for disabled people. Additional information is available on <a href="https://www.gov.uk/dla-disability-living-allowance-benefit">the government website</a>.',
              'enum': [
                'high',
                'medium',
                'low',
                'none'
                ]
              }, {
                type: 'select',
                titleMap: [
                  toTitleMapEntry('high', dlaCareAllowances.high.amount),
                  toTitleMapEntry('medium', dlaCareAllowances.medium.amount),
                  toTitleMapEntry('low', dlaCareAllowances.low.amount),
                  {value: 'none', name: '0'}
                ]
              }
            ),

            mobilityAllowance: fieldDefinitionService.number.mappedCurrencyField({
                title: 'DLA Mobility / PIP Mobility',
              'enum': [
                'high',
                'low',
                'none'
              ]
              }, {
                type: 'select',
                titleMap: [
                  toTitleMapEntry('high', mobilityAllowances.high.amount),
                  toTitleMapEntry('low', mobilityAllowances.low.amount),
                  {value: 'none', name: '0'}
                ]
              }
            ),

            pipDailyLiving: fieldDefinitionService.number.mappedCurrencyField({
              title: 'Personal Independence Payment (PIP) Daily Living',
              description: 'Personal Independence Payment (PIP) Daily Living is a payment to help you with some of the extra costs if you have long term ill-health or a disability. Additional information is available on <a href="https://www.gov.uk/pip">the government website</a>.',
              'enum': [
                'high',
                'low',
                'none'
              ]
            }, {
              type: 'select',
              titleMap: [
                toTitleMapEntry('high', pipDailyAllowances.high.amount),
                toTitleMapEntry('low', pipDailyAllowances.low.amount),
                {value: 'none', name: '0'}
              ]
            }),

            carersAllowance: fieldDefinitionService.number.currencyField({
              title: "Carer's Allowance",
              description: 'Carer\'s Allowance is a payment if you care for someone at least 35 hours a week and they receive certain benefits. Additional information is available on <a href="https://www.gov.uk/carers-allowance">the government website</a>.'
            }),
            carersAllowancePeriod: frequency(),

            incapacityBenefit: fieldDefinitionService.number.currencyField({
              title: 'Incapacity Benefit',
              description: 'Incapacity Benefit is a payment for people who cannot work because they are sick or disabled. Additional information is available on <a href="https://www.gov.uk/incapacity-benefit">the government website</a>.'
            }),
            incapacityBenefitPeriod: frequency(),

            warPension: fieldDefinitionService.number.currencyField({
              title: 'War Pension'
            }),
            warPensionPeriod: frequency(),

            warSpecialPayment: fieldDefinitionService.number.currencyField({
              title: 'War Special Payment'
            }),
            warSpecialPaymentPeriod: frequency(),

            childBenefit: fieldDefinitionService.number.currencyField({
              title: 'Child Benefit',
              description: 'Child Benefit is a payment if you’re responsible for bringing up a child who is:<ul><li> under 16</li><li> under 20 if they stay in approved education or training</li></ul> Additional information is available on <a href="https://www.gov.uk/child-benefit">the government website</a>.'
            }),
            childBenefitPeriod: frequency(),

            charitableIncome: fieldDefinitionService.number.currencyField(
              {title: 'Charitable Income'},
              {description: 'Please list all payments received from charities such as Freeman’s payment or payment from a religious organisation. These payments are fully disregarded'}
            ),
            charitableIncomePeriod: frequency(),

            hasOtherBenefit: fieldDefinitionService.boolean.yesNoSchema({
              title: 'Do you have any other income or benefit not listed above?'
            }),

            otherBenefit: {
              type: 'array',
              minItems: 1,
              title: 'Other Income/Benefit',
              items: {
                type: 'object',
                properties: {
                  otherBenefitAmount: fieldDefinitionService.number.currencyField(
                    { title: 'Amount' }
                  ),
                  otherBenefitPeriod: frequency(),
                  otherBenefitDescription: {
                    type: 'string',
                    title: 'Please provide more information about this other income/benefit',
                    'x-schema-form': {
                      type: 'textarea',
                      description: "If you wish to provide details of more than 1 source of additional income/benefit then these need to be provided separately. Please use the 'Add' button below."
                    }
                  }
                },
                required: [
                  'otherBenefitAmount',
                  'otherBenefitPeriod',
                  'otherBenefitDescription'
                ]
              }
            },

            pensionGuaranteeCredit: fieldDefinitionService.number.currencyField({
              title: 'Total Household Pension Credit Guarantee Element',
              description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/COV_FCA/templates/pensionCredit.html')
            }),

            pensionGuaranteeCreditPeriod: frequency(),

            pensionSavingsCredit: fieldDefinitionService.number.currencyField({
              title: 'Total Household Pension Credit Savings Element',
              description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/COV_FCA/templates/pensionCredit.html')
            }),

            pensionSavingsCreditPeriod: frequency(),

            ongoingApplications: fieldDefinitionService.boolean.yesNoSchema({
              title: 'Are you in the process of applying for any benefits or have you already applied and are awaiting a decision?'
            }),
            ongoingApplicationsInformation: {
              type: 'string',
              title: 'Ongoing benefit applications',
              'x-schema-form': {
                type: 'textarea',
                description: 'If you have applied for any benefits but have not yet heard whether you have been successful then please give details here, including what benefit it is and when you applied. These will not affect the calculation at the end of the form but might need to be re-assessed if you are successful.'
              }
            }
          },

          required: [
            'hasWage',
            'hasUniversalCredit',
            'hasPrivatePension',
            'workingTaxCreditPeriod',
            'childTaxCreditPeriod',
            'incomeSupportPaymentPeriod',
            'employmentSupportPeriod',
            'jobSeekersAllowancePeriod',
            'industrialInjuriesBenefitPeriod',
            'warWidowsPensionPeriod',
            'maintenancePeriod',
            'rentalIncomePeriod',
            'retirementPensionPeriod',
            'carersAllowancePeriod',
            'incapacityBenefitPeriod',
            'warPensionPeriod',
            'warSpecialPaymentPeriod',
            'childBenefitPeriod',
            'charitableIncomePeriod',
            'hasOtherBenefit',
            'pensionGuaranteeCreditPeriod',
            'pensionSavingsCreditPeriod',
            'ongoingApplications',
            'ongoingApplicationsInformation',
            'hasESAEnhancedDisabilityPremium'
          ]
        };

        return income;
      }

      var schema = {
        type: 'object',
        properties: {
          declaration: fieldDefinitionService.boolean.yesRequiredSchema({
            title: 'Do you understand and agree with the declaration set out above?',
            validationMessage: 'You must indicate that you understand and agree to the above declaration'
          }),
          otherInformation: {
            type: 'string',
            'x-schema-form': {
              type: 'textarea',
              htmlClass: 'other-info-free-text',
              title: "If you wish to add any further information, please enter the details here."
            }
          },
          clientID: {
            title: "Client ID",
            type: 'string',
            pattern: '^\\d+$',
            validationMessage: 'Only numbers are allowed in this field'
          },
          deprivationOfAssets: {
            type: "object",
            properties: {
              hasDisposedOfAssets: fieldDefinitionService.boolean.yesNoSchema({
                title: 'Have you sold, given away, gifted or put into trust any property, capital or assets?'
              }),
              disposedAssets: {
                type: 'object',
                properties: {
                  assetValue: fieldDefinitionService.number.currencyField({
                    title: 'Value of sale/transfer of capital / assets'
                  }),
                  dateOfDisposal: fieldDefinitionService.time.pastDateSchema({
                    title: 'When did you sell or transfer the capital / assets?'
                  }),
                  assetsDisposed: {
                    type: 'string',
                    title: 'What capital / assets did you sell or transfer?',
                    'x-schema-form': {
                      type: 'textarea'
                    }
                  },
                  reasonForDisposal: {
                    type: 'string',
                    title: 'What was the reason for sale or transfer of the capital / assets?',
                    'x-schema-form': {
                      type: 'textarea'
                    }
                  }
                },
                required: [
                  'assetValue',
                  'dateOfDisposal',
                  'assetsDisposed',
                  'reasonForDisposal'
                ]
              }
            },
            required: ['hasDisposedOfAssets']
          },
          joint: {
            type: 'object',
            properties: {
              capital: capital()
            },
            required: ['capital']
          },
          agent: {
            type: 'object',
            properties: {
              isCompletedByAgent: fieldDefinitionService.boolean.yesNoSchema({
                title: 'Has this form been filled in by someone other than the person applying?'
              }),

              completedByDetails: agentDetails({
                title: 'Filled in by someone else'
              }),

              hasAppointee: fieldDefinitionService.boolean.yesNoSchema({
                title: 'Is there anyone acting in capacity of Appointeeship for State Benefits?'
              }),
              appointeeDetails: agentDetails({
                title: 'Appointee for State Benefits'
              }),

              hasDeputy: fieldDefinitionService.boolean.yesNoSchema({
                title: 'Is there anyone acting in capacity of Deputy?'
              }),

              deputyDetails: {
                title: 'Deputy',
                type: 'array',
                minItems: 1,
                items: agentDetails({
                  title: 'Deputy'
                }),

              },

              hasPowerOfAttorney: fieldDefinitionService.boolean.yesNoSchema({
                title: 'Is there anyone acting in capacity of Lasting or Enduring Power of Attorney?'
              }),

              powerOfAttorneyDetails: {
                title: 'Power of Attorney',
                type: 'array',
                minItems: 1,
                items: agentDetails({
                  title: 'Power of Attorney'
                })

              }
            },
            required: [
              'isCompletedByAgent',
              'hasAppointee',
              'hasDeputy',
              'hasPowerOfAttorney',
            ]
          },
          partner: {
            type: 'object',
            properties: {
              intro: {
                type: 'object',
                properties: {
                  alreadyReceivingCare: fieldDefinitionService.boolean.yesNoSchema({
                    title: "Is your partner already receiving care services from the Council?"
                  }),
                },
                required: [
                  'alreadyReceivingCare'
                ]
              },
              details: {
                type: 'object',
                properties: {
                  title: {
                    title: 'Title',
                    type: 'string'
                  },
                  firstName: {
                    type: 'string',
                    title: 'First name'
                  },
                  middleName: {
                    type: 'string',
                    title: 'Middle name'
                  },
                  lastName: {
                    type: 'string',
                    title: 'Last name'
                  },
                  sameAddress: fieldDefinitionService.boolean.yesNoSchema({
                    title: 'Do you and your partner live at the same address?'
                  }),
                  address: {
                    type: 'object',
                    title: "Your partner's address",
                    properties: {
                      line1: addressField.requiredLine({title: 'Address line 1'}, true),
                      line2: addressField.requiredLine({title: 'Address line 2'}, true),
                      line3: addressField.optionalLine({title: 'Address line 3'}, true),
                      postcode: addressField.postcode({title: 'Postcode'})
                    },
                    required: ['line1', 'line2', 'postcode']
                  },
                  homePhoneNumber: fieldDefinitionService.phone.fullNumber({
                    title: 'Home phone number'
                  }),
                  mobilePhoneNumber: fieldDefinitionService.phone.fullNumber({
                    title: 'Mobile phone number'
                  }),
                  email: fieldDefinitionService.string.email({
                    title: 'Email address'
                  }),
                  nationalInsuranceNumber: fieldDefinitionService.NINO.fullNumber({
                    title: 'National insurance number'
                  }),
                  nhsNumber: {
                    type: 'string',
                    title: 'NHS number',
                    pattern: '^[0-9]{3}[\\s-]?[0-9]{3}[\\s-]?[0-9]{4}$',
                    validationMessage: '10 digits are expected, e.g. 123-456-7890'
                  },
                  dob: fieldDefinitionService.time.dateOfBirth(),
                  partnerReceivesResidentialCare: fieldDefinitionService.boolean.yesNoSchema({
                    title: "Is your spouse currently in residential care?"
                  }),
                },
                required: [
                  'firstName',
                  'lastName',
                  'sameAddress',
                  'address',
                  'nationalInsuranceNumber',
                  'dob',
                  'partnerReceivesResidentialCare'
                ]
              },
              income: incomeProperties(false),
              capital: capital()
            },
            required: [ 'intro', 'details', 'income', 'capital' ]
          },
          person: {
            type: 'object',
            properties: {
              property: {
                type: 'object',
                title: 'Property',
                properties: {
                  ownership: {
                    title: 'Property/tenancy details',
                    type: 'string',
                    'enum': ['ownedByYou', 'ownedJointly', 'councilTenant', 'rentedPrivately', 'other'],
                    'x-schema-form': {
                      titleMap: {
                        ownedByYou: 'Property Owned By You',
                        ownedJointly: 'Property Jointly Owned',
                        councilTenant: 'Council or Housing Association Tenant',
                        rentedPrivately: 'Property Rented From A Private Landlord',
                        other: 'Other (give details below)'
                      }
                    }
                  },
                  otherProperty: {
                    type: 'string',
                    title: 'Please provide your property / tenancy details',
                    'x-schema-form': {
                      type: 'textarea'
                    }
                  },
                  value: fieldDefinitionService.number.currencyField({
                    title: 'Amount of equity in this property/land'
                  }),
                  hasAdditionalProperties: fieldDefinitionService.boolean.yesNoSchema({
                    title: 'Other than your home, do you solely or jointly own any properties or land?',
                    description: 'Details of any property/land you have sold in the last five years will be requested on page 7.'
                  }),
                  additionalProperties: {
                    type: 'array',
                    minItems: 1,
                    items: {
                      type: "object",
                      properties: {
                        isJointlyOwned: fieldDefinitionService.boolean.yesNoSchema({
                          title: 'Is it jointly owned?'
                        }),
                        value: fieldDefinitionService.number.currencyField({
                          title: 'Amount of equity in this property/land'
                        })
                      },
                      required: ["isJointlyOwned", "value"]
                    }
                  }
                },

                required: [
                  'ownership',
                  'value',
                  'hasAdditionalProperties'
                ]
              },
              details: {
                type: 'object',
                properties: {
                  title: {
                    title: 'Title',
                    type: 'string'
                  },
                  firstName: {
                    type: 'string',
                    title: 'First name'
                  },
                  middleName: {
                    type: 'string',
                    title: 'Middle name'
                  },
                  lastName: {
                    type: 'string',
                    title: 'Last name'
                  },
                  dob: fieldDefinitionService.time.dateOfBirth(),
                  address: {
                    type: 'object',
                    properties: {
                      uprn: {type: 'string'},
                      line1: addressField.requiredLine(null, true),
                      line2: addressField.requiredLine(null, true),
                      line3: addressField.optionalLine(null, true),
                      postcode: addressField.postcode()
                    },
                    required: ['line1', 'line2', 'postcode']
                  },
                  homePhoneNumber: fieldDefinitionService.phone.fullNumber({
                    title: 'Home phone number'
                  }),
                  mobilePhoneNumber: fieldDefinitionService.phone.fullNumber({
                    title: 'Mobile phone number'
                  }),
                  email: fieldDefinitionService.string.email({
                    title: 'Email address'
                  }),
                  nationalInsuranceNumber: fieldDefinitionService.NINO.fullNumber({
                    title: 'National insurance number'
                  }),
                  nhsNumber: {
                    type: 'string',
                    title: 'NHS number',
                    pattern: '^[0-9]{3}[\\s-]?[0-9]{3}[\\s-]?[0-9]{4}$',
                    validationMessage: '10 digits are expected, e.g. 123-456-7890'
                  }
                },
                required: [
                  'firstName',
                  'lastName',
                  'dob',
                  'address',
                  'nationalInsuranceNumber'
                ]
              },
              intro: {
                type: 'object',
                properties: {
                  excludeNonResidentialCaretypes: fieldDefinitionService.boolean.yesNoSchema({
                    description: '<p>If your partner is also receiving a non-residential care service, we will review their DRE expenditure allowances in their financial assessment and allow 50% each for items considered joint expenditure where appropriate.</p>',
                    title: 'Are you only interested in applying for long-term funding in a residential care or nursing home?'
                  }),
                  provideFinancialDetails: {
                    title: 'If you would like the Council to assist with the cost of your care you must fully declare your financial circumstances',

                    // Ideally, this fields would be a boolean, but due to this ASF bug we can't have multiple radio buttons
                    // with the same value (false) https://github.com/json-schema-form/angular-schema-form/issues/887
                    type: 'string',
                    enum: ['payFullCost', 'agree', 'arrangeMyOwn'],
                    'x-schema-form': {
                      type: "radios",
                      titleMap: {
                        payFullCost: "I have savings/capital <strong>above £23,250</strong> in current accounts/all open accounts and agree for Coventry City Council to arrange care services for me and I agree to pay the full cost of my care. An initial administration charge of £262.15, with further annual administration charges of £143.00 will be applied to non-residential care packages only.",
                        agree: "I have savings/capital <strong>under £23,250</strong> in current accounts/all open accounts and agree to provide my financial details to check whether I may be entitled to help with the cost of my care.",
                        arrangeMyOwn: "I do not wish to provide my financial details and will arrange my own care."
                      }
                    },
                  },
                  hasPartner: fieldDefinitionService.boolean.yesNoSchema({
                    title: 'Do you have a partner?'
                  }),
                  partnerStatus: {
                    type: 'string',
                    'x-schema-form': {
                      type: "radios",
                      titleMap: [
                        {value: 'spouse', name: "Spouse"},
                        {value: 'civil_partner', name: "Civil partner"},
                        {value: 'partner', name: "Partner"}
                      ]
                    },
                    title: "Please state your relationship with your partner"
                  },
                  carersAllowanceIsEntitled: {
                    type: 'string',
                    'x-schema-form': {
                      type: "radios",
                      titleMap: [
                        {value: 'yes', name: "Yes"},
                        {value: 'no', name: "No"},
                        {value: 'unknown', name: "Don't know"}
                      ]
                    },
                    title: "Do you have an underlying entitlement to Carer's Allowance?"
                  },
                  propertyType: {
                    title: 'Please indicate the type of property that you live in',
                    type: 'string',
                    enum: [
                      'flat',
                      'semiDetached',
                      'detached'
                    ],
                    'x-schema-form': {
                      type: "radios",
                      titleMap: [
                        {value: 'flat', name: 'Flat/Terraced House'},
                        {value: 'semiDetached', name: 'Semi-detached House'},
                        {value: 'detached', name: 'Detached House'}
                      ]
                    }
                  }
                },
                required: [
                  'excludeNonResidentialCaretypes',
                  'carersAllowanceIsEntitled',
                  'provideFinancialDetails',
                  'hasPartner',
                  'partnerStatus',
                  'propertyType'
                ]
              },
              capital: capital(),
              income: incomeProperties(true),
              expenses: {
                type: 'object',
                title: 'Expenses',
                properties: {
                  mortgage: fieldDefinitionService.number.currencyField({
                    title: 'Mortgage'
                  }),
                  mortgagePeriod: frequency(),

                  mortgageLessHousing: fieldDefinitionService.number.currencyField({
                    title: 'Mortgage (less any housing costs paid through benefits you receive)'
                  }),
                  mortgageLessHousingPeriod: frequency(),

                  rentLessHousing: fieldDefinitionService.number.currencyField({
                    title: 'Rent (less housing benefit or universal credit paid to you or your landlord)'
                  }),
                  rentLessHousingPeriod: frequency(),

                  rent: fieldDefinitionService.number.currencyField({
                    title: 'Rent'
                  }),
                  rentPeriod: frequency(),

                  ongoingPropertyExpenses: fieldDefinitionService.number.currencyField({
                    title: 'Ongoing property expenses'
                  }),
                  ongoingPropertyExpensesPeriod: frequency(),

                  councilTax: fieldDefinitionService.number.currencyField({
                    title: 'Annual Council Tax'
                  }),

                  disabilityRelated: {
                    type: 'object',
                    properties: {
                      waterRates: fieldDefinitionService.number.currencyField({
                        title: 'Metered Water Costs'
                      }),
                      waterRatesPeriod: frequency(),

                      fuel: fieldDefinitionService.number.currencyField({
                        title: 'Fuel/Heating'
                      }),
                      fuelPeriod: frequency(),

                      gardening: fieldDefinitionService.number.currencyField({
                        title: 'Annual Gardening Costs'
                      }),

                      domesticHelp: fieldDefinitionService.number.currencyField({
                        title: 'Private Domestic Help'
                      }),
                      domesticHelpPeriod: frequency(),

                      transport: fieldDefinitionService.number.currencyField({
                        title: 'Transport/Taxis'
                      }),
                      transportPeriod: frequency(),

                      windowCleaner: fieldDefinitionService.number.currencyField({
                        title: 'Window Cleaner'
                      }),
                      windowCleanerPeriod: frequency(),

                      chiropodist: fieldDefinitionService.number.currencyField({
                        title: 'Chiropodist'
                      }),
                      chiropodistPeriod: frequency(),

                      washingPerWeek: {
                        title: 'How many Loads of washing do you do for yourself per week?',
                        type: 'number',
                        minimum: 0
                      },

                      poweredBed: fieldDefinitionService.number.currencyField({
                        title: 'Powered Bed/Pressure Mattress'
                      }),

                      bedTurning: fieldDefinitionService.number.currencyField({
                        title: 'Bed Turning'
                      }),

                      mobilityScooter: fieldDefinitionService.number.currencyField({
                        title: 'Mobility Scooter'
                      }),

                      stairLift: fieldDefinitionService.number.currencyField({
                        title: 'Stair Lift'
                      }),

                      hoistPurchase: fieldDefinitionService.number.currencyField({
                        title: 'Hoist'
                      }),

                      manualWheelchairPurchase: fieldDefinitionService.number.currencyField({
                        title: 'Manual Wheelchair Purchase'
                      }),

                      poweredWheelchairPurchase: fieldDefinitionService.number.currencyField({
                        title: 'Powered Wheelchair Purchase'
                      }),

                      manualWheelchairHire: fieldDefinitionService.number.currencyField({
                        title: 'Manual Wheelchair Hire'
                      }),
                      manualWheelchairHirePeriod: frequency(),

                      poweredWheelchairHire: fieldDefinitionService.number.currencyField({
                        title: 'Powered Wheelchair Hire'
                      }),
                      poweredWheelchairHirePeriod: frequency(),

                      specialClothes: fieldDefinitionService.number.currencyField({
                        title: 'Clothing/specialist footwear'
                      }),
                      specialClothesPeriod: frequency(),

                      specialClothesDescription: {
                        type: 'string',
                        title: 'Description',
                        'x-schema-form': {
                          type: 'textarea'
                        }
                      },

                      equipment: fieldDefinitionService.number.currencyField({
                        title: 'Equipment maintenance, repair, insurance costs'
                      }),
                      equipmentPeriod: frequency(),

                      equipmentDescription: {
                        type: 'string',
                        title: 'Description',
                        'x-schema-form': {
                          type: 'textarea'
                        }
                      },

                      alarm: fieldDefinitionService.number.currencyField({
                        title: 'Care Call/Community Alarm'
                      }),
                      alarmPeriod: frequency(),

                      incontinenceAids: fieldDefinitionService.number.currencyField({
                        title: 'Incontinence aids'
                      }),
                      incontinenceAidsPeriod: frequency(),

                      incontinenceAidsDescription: {
                        type: 'string',
                        title: 'Description',
                        'x-schema-form': {
                          type: 'textarea'
                        }
                      },

                      additionalBedding: fieldDefinitionService.number.currencyField({
                        title: 'Additional bedding'
                      }),
                      additionalBeddingPeriod: frequency(),

                      creamsAndWipes: fieldDefinitionService.number.currencyField({
                        title: 'Creams / wet wipes'
                      }),
                      creamsAndWipesPeriod: frequency(),

                      creamsAndWipesDescription: {
                        type: 'string',
                        title: 'Description',
                        'x-schema-form': {
                          type: 'textarea'
                        }
                      },

                      dietary: fieldDefinitionService.number.currencyField({
                        title: 'Dietary'
                      }),
                      dietaryPeriod: frequency(),

                      dietaryDescription: {
                        type: 'string',
                        title: 'Description',
                        'x-schema-form': {
                          type: 'textarea'
                        }
                      },

                      communicationEquipment: fieldDefinitionService.number.currencyField({
                        title: 'Communication equipment'
                      }),

                      internetAccess: fieldDefinitionService.number.currencyField({
                        title: 'Internet access'
                      }),
                      internetAccessPeriod: frequency(),

                      holidayCarer: fieldDefinitionService.number.currencyField({
                        title: 'Holiday - cost of carer'
                      }),

                      framesAndWalkers: fieldDefinitionService.number.currencyField({
                        title: 'Frames / Walkers'
                      }),

                      poweredRecliningChair: fieldDefinitionService.number.currencyField({
                        title: 'Powered reclining chair'
                      }),

                      hasOtherDRE: fieldDefinitionService.boolean.yesNoSchema({
                        title: 'Do you have any other Disability-Related expenses ?'
                      }),

                      otherDRE: {
                        type: 'array',
                        minItems: 1,
                        title: 'Other Disability-Related Expenses',
                        items: {
                          type: 'object',
                          properties: {
                            otherAmount: fieldDefinitionService.number.currencyField(
                              { title: 'Amount' }
                            ),
                            otherAmountPeriod: frequency(),
                            otherCostsDescription: {
                              type: 'string',
                              title: 'What are these other disability related expenses for?',
                              description: 'For purposes of this assessment these expenses will not be taken into account however Coventry City Council will be in touch to review these with you on a case by case basis',
                              'x-schema-form': {
                                type: 'textarea'
                              }
                            }
                          },
                          required: [
                            'otherAmount',
                            'otherAmountPeriod',
                            'otherCostsDescription'
                          ]
                        }
                      },

                      expensesAboveMaxThreshold: {
                        type: 'array',
                        minItems: 0,
                        convertKeysToLabels: 'person.expenses.disabilityRelated',
                        items: {
                          type: 'string',
                        },
                        title: 'Disability-related expenses above maximum threshold',
                      }

                    },
                    required: [
                      'waterRatesPeriod',
                      'fuelPeriod',
                      'domesticHelpPeriod',
                      'transportPeriod',
                      'windowCleanerPeriod',
                      'chiropodistPeriod',
                      'manualWheelchairHirePeriod',
                      'poweredWheelchairHirePeriod',
                      'specialClothesPeriod',
                      'equipmentPeriod',
                      'alarmPeriod',
                      'hasOtherDRE'
                    ]
                  }
                },
                required: [
                  'compulsoryCourtPaymentsPeriod',
                  'disabilityRelated',
                  'mortgagePeriod',
                  'mortgageLessHousingPeriod',
                  'rentPeriod',
                  'rentLessHousingPeriod'
                ]
              }
            },
            required: ['details', 'intro', 'capital', 'income', 'expenses', 'benefitsAdvice', 'property']
          },
          peopleLivingWithYou: {
            type: 'object',
            properties: {
              hasDependentChildren: fieldDefinitionService.boolean.yesNoSchema({
                title: 'Do you have dependent children that live with you?'
              }),
              dependentChildren: {
                type: 'array',
                minItems: 1,
                items: {
                  type: "object",
                  properties: {
                    title: {
                      title: 'Title',
                      type: 'string'
                    },
                    name: {
                      title: 'Name',
                      type: 'string'
                    },
                    dateOfBirth: fieldDefinitionService.time.dateOfBirth({
                      'x-schema-form': {
                        validationMessage: 'A dependent child must be under 18 years old',
                        type: 'memorabledate',
                        dateFormat: "DD/MM/YYYY",
                        maxDate: new Date(),
                        minDate: dateUtils.getYearsBeforeNow(18)
                      }
                    })
                  },
                  required: ["name", "dateOfBirth"]
                }
              },
              hasOtherPeople: fieldDefinitionService.boolean.yesNoSchema({
                title: 'Do you have other people living with you?'
              }),
              otherPeople: {
                type: 'array',
                minItems: 1,
                items: {
                  type: "object",
                  properties: {
                    title: {
                      title: 'Title',
                      type: 'string'
                    },
                    name: {
                      title: 'Name',
                      type: 'string'
                    },
                  },
                  required: ["name"]
                }
              }
            },
            required: ['hasDependentChildren', 'hasOtherPeople']
          },

          careAssessment: {
            type: 'object',
            properties: {
              nonResidential: {
                type: 'object',
                properties: {
                  maxContribution: {
                    type: 'number',
                    minimum: 0,
                    'x-schema-form': {
                      fieldAddonLeft: '£'
                    }
                  },
                  workings: getBreakdown()
                },
                required: ['workings']
              },
              respite: {
                type: 'object',
                properties: {
                  maxContribution: {
                    type: 'number',
                    minimum: 0,
                    'x-schema-form': {
                      fieldAddonLeft: '£'
                    }
                  },
                  workings: getBreakdown()
                },
                required: ['workings']
              },
              week1Residential: {
                type: 'object',
                properties: {
                  maxContribution: {
                    type: 'number',
                    minimum: 0,
                    'x-schema-form': {
                      fieldAddonLeft: '£'
                    }
                  },
                  workings: getBreakdown()
                },
                required: ['workings']
              },
              week5Residential: {
                type: 'object',
                properties: {
                  maxContribution: {
                    type: 'number',
                    minimum: 0,
                    'x-schema-form': {
                      fieldAddonLeft: '£'
                    }
                  },
                  workings: getBreakdown()
                },
                required: ['workings']
              },
              week13Residential: {
                type: 'object',
                properties: {
                  maxContribution: {
                    type: 'number',
                    minimum: 0,
                    'x-schema-form': {
                      fieldAddonLeft: '£'
                    }
                  },
                  workings: getBreakdown()
                },
                required: ['workings']
              }
            },
            required: [
              'nonResidential',
              'respite',
              'week1Residential',
              'week5Residential',
              'week13Residential'
            ]
          }
        },
        required: [
          'agent',
          'person',
          'partner',
          'careAssessment',
          'peopleLivingWithYou',
          'joint',
          'deprivationOfAssets',
          'declaration'
        ]
      };

      return {
        getSchema: function () {
          return schema;
        }
      };
    }]);
